import React from "react";
import ReactDOM from "react-dom/client";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./index.css";
import App from "./components/app.jsx";
import tracks from "./data/tracks.js";
import backgrounds from "./data/backgrounds.js";

library.add(fas);
library.add(fab);

// Register the service worker
// if("serviceWorker" in navigator){
//   navigator.serviceWorker.register("sw.js");
// }

const appConfig = {
  fontSize: 16, // App font size in rem
  trackSize: 10, // Individual track size in rem
  progressBarThumbSize: 0.75, // Progress bar thumb size in rem
  mobileGutter: 1, // `DisplayTracks` component gutter for mobile layout in rem
  mobileMinScale: 0.2, // The minimum scale factor when resizing tracks for mobile layout, meaning a track cannot be scaled smaller than x% of its original size
  mobileBehindOpacity: 0.5,
  breakpoint: "767.98px",
  colorRef: {
    colorFullLight: "#FFFFFF",
    colorFullDark: "#000000",
    colorMid: "#7F7F7F",
    colorRangeMultiple: 1.8    
  },
  audioPlayerControls: {
    colorControlLight: "#EBEBEB",
    colorControlLightHover: "#FFFFFF",
    colorControlInverse: "#1F1F1F",
    colorControlInverseHover: "#000000",
    colorProgressBar: "rgb(0, 0, 0, 0.5)",
    colorProgressBarInverse: "rgb(255, 255, 255, 0.5)",
    // colorProgressBarThumb: "rgb(0, 0, 0, 1)",
    colorProgressBarThumb: "rgb(255, 165, 0, 1)",
    // colorProgressBarThumbInverse: "rgb(255, 255, 255, 1)",
    colorProgressBarThumbInverse: "rgb(255, 165, 0, 1)"
  },
  defaultBackground: {
    "name": "alien planet 1",
    "palette": ["#494c44", "#07192d", "#134d6a", "#3e7e8a"]
  }
}

const fisherYatesShuffle = (arr) => {
  /** The `fisherYatesShuffle` method shuffles the supplied array in place.
   * 
   * @param {array} arr
   * @return undefined
   * @catch {Error} err 
   */

  try {
    let a = arr.length - 1;
    let r, temp;
    while (a > 0) {
      r = Math.floor(Math.random() * a);
      temp = arr[r];
      arr[r] = arr[a];
      arr[a] = temp;
      a = a - 1;
    }
  } catch (err) {
    console.error(err);
  }
};

const getRandomTracksOrder = (tracks) => {
  /**
   * The `getRandomTracksOrder` method randomizes the track order.
   * 
   * @param {object} tracks
   * @return {object} _randTracks
   * @catch {Error} err
   */

  try {      
    // Randomize the track names
    let _keys = Object.keys(tracks);
    fisherYatesShuffle(_keys);

    return _keys
  } catch (err) {
    console.error(err);
  }
};

const getRandomArrayIndex = (arr) => {
  /**
   * The `getRandomArrayIndex` method chooses a random array item. It returns the randomly chosen index
   * and the 
   * 
   * @param {array} arr
   * @return {int} r Random index
   * @catch {Error} err
   */

  try {
      let r = Math.round(Math.random() * (arr.length - 1));
      return r
  } catch (err) {
      console.error(err);
  }
};

const getLightOrDarkForText = (bgIndex) => {
  /**
   * The `getLightOrDarkForText` method determines if the `Controls` component should use light or
   * dark text based on the selected background.
   * 
   * @param {int} bgIndex The index of the selected background
   * @return {string} _ `light` or `dark`
   * @catch {Error} err
   */
  try {
    let centerColor = parseInt(backgrounds[bgIndex]["centerColor"].replace("#", ""), 16);
    let lightColor = parseInt(appConfig.colorRef.colorFullLight.replace("#", ""), 16);
    let midColor = parseInt(appConfig.colorRef.colorMid.replace("#", ""), 16);
    let range = parseInt((lightColor - midColor) * appConfig.colorRef.colorRangeMultiple);

    // DEBUG
    // console.group();
    //   console.log(`centerColor: ${centerColor}`);
    //   console.log(`lightcolor: ${lightColor}`);
    //   console.log(`midColor: ${midColor}`);
    //   console.log(`range: ${range}`);
    //   console.log(`light - center: ${lightColor - centerColor}`);
    // console.groupEnd();
    // DEBUG
    return (range > (lightColor - centerColor)) ? "light" : "dark";
  } catch (err) {
    console.error(err);
  }
}

const setControlsTextColor = (textColor) => {
  /**
   * The `setControlsTextColor` method sets the CSS value for the `--control-text-color`,
   * `--control-text-hover-color`, `--progress-bar-color`, and `--progress-bar-thumb-color` variables.
   * 
   * @param {string} textColor `light` or `dark`
   * @return void
   * @catch {Error} err
   */
  try {
    // Get the root element
    const r = document.querySelector(":root");

    // Set the value of variable `--controls-text-color`
    if ("light" === textColor) {
      r.style.setProperty("--control-text-color", appConfig.audioPlayerControls.colorControlLight);
      r.style.setProperty("--control-text-hover-color", appConfig.audioPlayerControls.colorControlLightHover);
      r.style.setProperty("--progress-bar-background-color", appConfig.audioPlayerControls.colorProgressBar);
      r.style.setProperty("--progress-bar-thumb-color", appConfig.audioPlayerControls.colorProgressBarThumb);
    }
    if ("dark" === textColor) {
      r.style.setProperty("--control-text-color", appConfig.audioPlayerControls.colorControlInverse);
      r.style.setProperty("--control-text-hover-color", appConfig.audioPlayerControls.colorControlInverseHover);
      r.style.setProperty("--progress-bar-background-color", appConfig.audioPlayerControls.colorProgressBarInverse);
      r.style.setProperty("--progress-bar-thumb-color", appConfig.audioPlayerControls.colorProgressBarThumbInverse);
    }

    return
  } catch (err) {
    console.error(err);
  }
}

let bgURL, textColor, tracksOrder, bgColorIndex, bgColor;
if (navigator.onLine) {
  // Get a background
  let bgIndex = getRandomArrayIndex(backgrounds);
  bgURL = backgrounds[bgIndex]["url"];

  // Set `Controls` text color (light or dark)
  textColor = getLightOrDarkForText(bgIndex);

  // Get random background color for tracks
  bgColorIndex = getRandomArrayIndex(backgrounds[bgIndex]["palette"]);
  bgColor = backgrounds[bgIndex]["palette"][bgColorIndex];
} else {
  // User is offline; default background is `alien_planet_1`
  bgURL = null;
  textColor = "light";
  bgColorIndex = getRandomArrayIndex(appConfig.defaultBackground.palette);
  bgColor = appConfig.defaultBackground.palette[bgColorIndex];
}

// Set color of audio controls
setControlsTextColor(textColor);

// Get randomized tracks order
tracksOrder = getRandomTracksOrder(tracks);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App appConfig = { appConfig } tracks = { tracks } tracksOrder = { tracksOrder } bgColor = { bgColor } bgURL = { bgURL }/>
  </React.StrictMode>
);