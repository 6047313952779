import { useEffect } from "react";
import SideNavOptionWrapper from "./sidenav-option-wrapper";
import styles from "./sidepanel.module.css";

const SideNav = ({ isSideNavVisible, setIsSideNavVisible, setIsBioModalVisible, setIsShareModalVisible, strings }) => {
    const CSSStyles = {
        "sidePanelSmall": { "width": "15rem" },
        "sidePanelFull": { "width": "100%" },
        "overflowScroll": "scroll",
        "overflowHidden": "hidden"
    }
    
    const ids = {
        "area": { "id": "sideNavArea", "styles": { "close": "-" + CSSStyles["sidePanelSmall"]["width"], "open": "0" }},
        "close": { "id": "sideNavClose" }
    }

    const sideNavStrings = strings.sideNav;

    const sideNavButtonClickHandler = (e) => {
        e.stopPropagation();
    
        // Get the nav option that was clicked using 'data-nav-key' attribute
        let _navKey = e.currentTarget.attributes.getNamedItem("data-nav-key").value;
    
        switch (_navKey) {
            case ("bio"):
                // This click event is to display the bio modal
                setIsBioModalVisible(true);
                setIsSideNavVisible((prev) => !prev);
                break;
            case ("share"):
                // This click event is to display the share modal
                setIsShareModalVisible(true);
                setIsSideNavVisible((prev) => !prev);
                break;
            default:
                // pass
                break;
        }
    }
    
    useEffect(() => {
        const sidePanelOpen = () => {
            // Side panel menu is closed; open it and hide overflow on body
            document.getElementById(ids.area["id"]).style.right = ids.area["styles"]["open"];
            // document.body.style.overflowY = CSSStyles.overflowHidden;
        }
        
        const sidePanelClose = () => {
            // Side panel is open; close it and reset scroll on body
            document.getElementById(ids.area["id"]).style.right = ids.area["styles"]["close"];
            // document.body.style.overflowY = CSSStyles.overflowScroll;
        }
                
        if (1 === isSideNavVisible) {
            sidePanelOpen("sideNavArea");
        } else {
            sidePanelClose("sideNavArea");
        }
    }, [isSideNavVisible])


    return(
        <div id = { ids.area.id } className = { styles.sidePanel }>
            <div className = "text-end">
                <button id = { ids.close.id } type = "button" className = "d-inline-block btn-close me-3 mt-3" aria-label = "Close" onClick = {() => { setIsSideNavVisible(0) }}></button>
            </div>
            <ul className = "nav flex-column">
                {
                    Object.keys(sideNavStrings).map((_key, _index) => {
                        return(
                            <SideNavOptionWrapper key = { sideNavStrings[_key]["label"] } label = { sideNavStrings[_key]["label"]} navKey = { sideNavStrings[_key]["navKey"] } icon = { sideNavStrings[_key]["icon"] } handlers = {{ click: sideNavButtonClickHandler }}></SideNavOptionWrapper>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default SideNav