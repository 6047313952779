const esStrings = {
    langs: {
        "english": "EN",
        "spanish": "ES",
        "portuguese": "BR"
    },
    nav: {
        nav1: { "label": "bio", "icon": "", "navKey": "bio" },
        // nav2: { "label": "compra", "icon": "", "navKey": "merch" },
        nav3: { "label": "idioma", "icon": "fa-solid fa-globe", "navKey": "language" },
        nav4: { "label": "menu", "icon": "fa-solid fa-bars", "navKey": "side-panel-menu" }
    },
    sideNav: {
        nav1: { "label": "bio", "icon": "", "navKey": "bio" },
        // nav2: { "label": "compra", "icon": "", "navKey": "merch" },
        nav3: { "label": "redes sociales", "icon": "", "navKey": "share" }     
    },
    notifications: {
        loadingTrack: { "label": "Espere por favor...cargando pista...", "icon": "" },
        hifiBandcampDownload: { "label": "Descarga Hi-Fi disponible en Bandcamp", "href": "https://wevoyagersmusic.bandcamp.com", "svg": "bandcamp" },
        notOnline: { "label": "No estás en línea. Conéctate a Internet." }
    },
    bio: {
        modalCloseBtn: { "label": "cerrar" },
        title: { "label": "el fin de un nuevo comienzo..." },
        paragraph1: { "text": `We\\Voyagers comenzó como un proyecto de música experimental mientras yo buscaba 
            un nuevo comienzo musical. Evolucionó hacia un estilo de música electrónica progresiva, explorando
            una fusión de percusión orgánica, el ambiente y cantos africanos...entre otros sonidos. Ahora estoy
            en el final de mi nuevo comienzo y te invito a que me acompañes en este viaje, mi compañero de viaje.`
        }
    },
    share: {
        modalCloseBtn: { "label": "cerrar" },
        title: { "label": "comparte We\\Voyagers" },
        text1: { "text": "Comparte We\\Voyagers en Facebook, Reddit, Telegram, y Twitter." }
    },
    social: {
        hashtag: "#We\\Voyagers",
        url: "https://wevoyagersmusic.com",
        title: "We\\Voyagers - Música electrónica progresiva",
        description: "Reúnenos, mi compañero de viaje, en esta exploración de música electrónica progresiva, percusiva, y ambiente.",
        quote: "We\\Voyagers",
        type: "music.playlist",
        image: "https://storage.googleapis.com/we-voyagers/images/rune_raidho_1_1024x1024_colored_for_social_with_name_2.png"
    }    
}

export default esStrings