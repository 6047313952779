import { useContext } from "react";
import { AppContext } from "./app-context.jsx";
import NavOptionWrapper from "./nav-option-wrapper.jsx";
import styles from "./nav.module.css";

const Nav = ({ setIsSideNavVisible, setIsBioModalVisible, switchLanguage, strings, setIsPlaying, trackIndex }) => {
    // Get app context
    const context = useContext(AppContext);
    const ids = context.ids;
    const trackBlink = context.trackBlink;
    const trackFullOpacity = context.trackFullOpacity;
    const trackHighlight = context.trackHighlight;

    const langs = strings.langs;
    const navStrings = strings.nav;

    const navButtonClickHandler = (e) => {
        e.stopPropagation();
    
        // Stop any playback and remove playback classes from current track
        trackBlink("remove", trackIndex);
        trackFullOpacity("remove", trackIndex);
        trackHighlight("remove", trackIndex);
        setIsPlaying(false);        

        // Get the nav option that was clicked using 'data-nav-key' attribute
        let _navKey = e.currentTarget.attributes.getNamedItem("data-nav-key").value;
    
        switch (_navKey) {
            case ("side-panel-menu"):
                // This click event is for the side panel menu
                setIsSideNavVisible(1);
                break;
            case ("bio"):
                // This click event is to display the bio modal
                setIsBioModalVisible(true);
                break;
          default:
            // pass
        }
    }

    return(
        <ul id = { ids.navArea.id } className = { [styles.navArea, "nav", "justify-content-end", "align-content-center"].join(" ") }>
            {
                Object.keys(navStrings).map((key) => {
                    return(
                        <NavOptionWrapper
                            key = { navStrings[key]["label"] }
                            handlers = {{ nav: { click: navButtonClickHandler }, lang: { click: switchLanguage }}}
                            label = { navStrings[key]["label"] }
                            icon = { navStrings[key]["icon"] }
                            navKey = { navStrings[key]["navKey"] }
                            langs = { langs }>
                        </NavOptionWrapper>
                    )
                })
            }
        </ul>
    )
}

export default Nav