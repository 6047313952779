import styles from "./logo.module.css";

const Logo = () => {
    return(
        <div className = { [styles.logoArea, "row", "align-content-center"].join(" ") }>
            <div className = { styles.logo }>We\Voyagers</div>
        </div>
    )
}

export default Logo