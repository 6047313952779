const enStrings = {
    langs: {
        "english": "EN",
        "spanish": "ES",
        "portuguese": "BR"
    },
    nav: {
        nav1: { "label": "bio", "icon": "", "navKey": "bio" },
        // nav2: { "label": "merch", "icon": "", "navKey": "merch" },
        nav3: { "label": "language", "icon": "fa-solid fa-globe", "navKey": "language" },
        nav4: { "label": "menu", "icon": "fa-solid fa-bars", "navKey": "side-panel-menu" }
    },
    sideNav: {
        nav1: { "label": "bio", "icon": "", "navKey": "bio" },
        // nav2: { "label": "merch", "icon": "", "navKey": "merch" },
        nav3: { "label": "share", "icon": "", "navKey": "share" }
    },
    notifications: {
        loadingTrack: { "label": "Please wait...loading track...", "icon": "" },
        hifiBandcampDownload: { "label": "Hi-Fi download available on Bandcamp", "href": "https://wevoyagersmusic.bandcamp.com", "svg": "bandcamp" },
        notOnline: { "label": "You are not online. Please connect to the Internet." }
    },
    bio: {
        modalCloseBtn: { "label": "close" },
        title: { "label": "the end of a beginning..." },
        paragraph1: { "text": `We\\Voyagers began as an experimental project as I was searching for a 
            new musical beginning. It evolved into a kind of progressive electronica, exploring fusions of
            organic percussion, ambient soundscapes, and African chants...among other sounds. I find myself
            at the end of this new beginning and I welcome you to join me on this journey, my fellow voyager.`
        }
    },
    share: {
        modalCloseBtn: { "label": "close" },
        title: { "label": "share We\\Voyagers" },
        text1: { "text": "Share We\\Voyagers on Facebook, Reddit, Telegram, and Twitter." }
    },
    social: {
        hashtag: "#We\\Voyagers",
        url: "https://wevoyagersmusic.com",
        title: "We\\Voyagers - Progressive electronic music",
        description: "Join us, fellow voyager, on this exploration of progressive, percussive, and ambient electronica.",
        quote: "We\\Voyagers",
        type: "music.playlist",
        image: "https://storage.googleapis.com/we-voyagers/images/rune_raidho_1_1024x1024_colored_for_social_with_name_2.png"
    }
}

export default enStrings