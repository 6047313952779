const brStrings = {
    langs: {
        "english": "EN",
        "spanish": "ES",
        "portuguese": "BR"
    },
    nav: {
        nav1: { "label": "bio", "icon": "", "navKey": "bio" },
        // nav2: { "label": "comprar", "icon": "", "navKey": "merch" },
        nav3: { "label": "linguagem", "icon": "fa-solid fa-globe", "navKey": "language" },
        nav4: { "label": "menu", "icon": "fa-solid fa-bars", "navKey": "side-panel-menu" }
    },
    sideNav: {
        nav1: { "label": "bio", "icon": "", "navKey": "bio" },
        // nav2: { "label": "comprar", "icon": "", "navKey": "merch" },
        nav3: { "label": "mídia social", "icon": "", "navKey": "share" }        
    },
    notifications: {
        loadingTrack: { "label": "Por favor aguarde...carregando faixa...", "icon": "" },
        hifiBandcampDownload: { "label": "Download Hi-Fi disponível no Bandcamp", "href": "https://wevoyagersmusic.bandcamp.com", "svg": "bandcamp" },
        notOnline: { "label": "Você não está online. Conecte-se à Internet." }
    },
    bio: {
        modalCloseBtn: { "label": "fechar" },
        title: { "label": "o fim de um novo começo..." },
        paragraph1: { "text": `We\\Voyagers começamos como um projeto de música experimental enquanto eu 
            buscava por um novo começo. Evoluiu para uma espécie de música eletrônica progressiva, explorando
            fusões de percussão orgânica, paisagens sonoras ambientes e cantos africanos...entre outros sons. 
            Encontro-me no final deste novo começo e convido você a se juntar a mim nesta jornada, meu 
            companheiro de viagem.`
        }
    },
    share: {
        modalCloseBtn: { "label": "fechar" },
        title: { "label": "compartilhe We\\Voyagers" },
        text1: { "text": "Compartilhe We\\Voyagers no Facebook, Reddit, Telegram e Twitter." }
    },
    social: {
        hashtag: "#We\\Voyagers",
        url: "https://wevoyagersmusic.com",
        title: "We\\Voyagers - Música eletrônica progressiva",
        description: "Junte-se a nós, companheiro viajante, nesta exploração da música eletrônica progressiva, percussiva e ambiente.",
        quote: "We\\Voyagers",
        type: "music.playlist",
        image: "https://storage.googleapis.com/we-voyagers/images/rune_raidho_1_1024x1024_colored_for_social_with_name_2.png"
    }       
}

export default brStrings