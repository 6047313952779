const backgrounds = [
    { 
        "name": "alien planet 1",
        "url": "https://storage.googleapis.com/we-voyagers/backgrounds/alien_planet_1_3248_1827_centered.jpg",
        "palette": ["#494c44", "#07192d", "#134d6a", "#3e7e8a"],
        "centerColor": "#1e5460"
    },
    {
        "name": "galaxy 1",
        "url": "https://storage.googleapis.com/we-voyagers/backgrounds/galaxy_1_3712_2088_centered.jpg",
        "palette": ["#222118", "#1f345c", "#061216", "#505e96"],
        "centerColor": "#fffff"
    },
    {
        "name": "alien landscape 1",
        "url": "https://storage.googleapis.com/we-voyagers/backgrounds/alien_landscape_1_4096_2034.jpg",
        "palette": ["#0f91cf", "#12c4e8", "#035797"],
        "centerColor": "#9ce3db"
    },
    {
        "name": "alien landscape 2",
        "url": "https://storage.googleapis.com/we-voyagers/backgrounds/alien_landscape_2_4096_2034_centered.jpg",
        "palette": ["#0f91cf", "#12c4e8", "#035797"],
        "centerColor": "#71bfa5"
    },
    // {
    //     "name": "alien landscape 3",
    //     "url": "https://storage.googleapis.com/we-voyagers/backgrounds/alien_landscape_3_4096_2034.jpg",
    //     "palette": ["#950b22", "#9f5fb5", "#d14e92"],
    //     "centerColor": "#b2bfe2"
    // },
    {
        "name": "hibernation pod 1",
        "url": "https://storage.googleapis.com/we-voyagers/backgrounds/hibernation_pod_1_3792_2304_centered.jpg",
        "palette": ["#a51c14", "#3c292f", "#1e0a13", "#786662"],
        "centerColor": "#5b5653"
    },    
    {
        "name": "hibernation pod 2",
        "url": "https://storage.googleapis.com/we-voyagers/backgrounds/hibernation_pod_2_3792_2304_centered.jpg",
        "palette": ["#a51c14", "#3c292f", "#1e0a13", "#786662"],
        "centerColor": "#5b5653"
    },
    // {
    //     "name": " alien sky 2",
    //     "url": "https://storage.googleapis.com/we-voyagers/backgrounds/alien_sky_2_4096_2034.jpg",
    //     "palette": ["#b12530", "#d82e36", "#f83e43"],
    //     "centerColor": "#fd6260"
    // }    
]

export default backgrounds